import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  Modal,
  IconButton,
} from '@mui/material';
import HotelRoomGroup from './hotel-room-group';
import HotelHouseRules from './hotel-house-rules';
import HotelFacilities from './hotel-facilities';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/store';
import parse from 'html-react-parser';
import CloseIcon from '@mui/icons-material/Close';

interface CurrencyRate {
  ccy2: string;
  blockchain2?: string;
  rate: number;
  prec?: string;
}

interface SelectedCrypto {
  cryptoName: string;
  blockchain2?: string;
}

interface DescriptionStruct {
  paragraphs: string[];
  title: string;
}

interface NameStruct {
  bathroom: string;
  bedding_type: string;
  main_name: string;
}
interface RoomGroups {
  name: string;
  images: string[];
  policy_struct: PolicyStruct[];
  room_amenities: string[];
  rg_ext: any;
  room_group_id: string;
  name_struct: NameStruct;
}
interface AmenityGroups {
  amenities: string[];
  group_name: string;
}
interface PolicyStruct {
  paragraphs: string[];
  title: string;
}

interface RoomOption {
  meal: string;
  payment_options: any;
}

interface HotelRoomData {
  [roomName: string]: RoomOption[];
}

interface HotelDetailsProps {
  data: {
    images: string[];
    star_rating: number;
    rating: number;
    reviews: number;
    name: string;
    address: string;
    distance: string;
    amenities: string[];
    price: string;
    priceInBTC: string;
    id: string;
    description_struct: DescriptionStruct[];
    room_groups: RoomGroups[];
    amenity_groups: AmenityGroups[];
    check_in_time: string;
    check_out_time: string;
    metapolicy_extra_info: string;
    policy_struct: PolicyStruct[];
  };
  hotelRoomData: HotelRoomData[];
  roomCount: number;
}

const HotelDetails: React.FC<HotelDetailsProps> = ({
  data,
  hotelRoomData,
  roomCount,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const maxVisibleImages = 7;
  const totalImages = data?.images?.length;
  const remainingImages = totalImages - maxVisibleImages;

  // Filter Area
  const [roomGroupName, setRoomGroupName] = useState<any>([]);
  const [roomGroupMeal, setRoomGroupMeal] = useState<any>([]);

  const [selectedOptionRoomType, setSelectedOptionRoomType] =
    useState<any>('all-room');
  const [selectedOptionMealType, setSelectedOptionMealType] =
    useState<any>('all-meal');

  const handleChangeRoomType = (event: SelectChangeEvent) => {
    setSelectedOptionRoomType(event.target.value as string);
  };

  const handleChangeMealType = (event: SelectChangeEvent) => {
    setSelectedOptionMealType(event.target.value as string);
  };

  useEffect(() => {
    const roomNameSet = new Set<string>();
    const mealSet = new Set<string>();

    hotelRoomData.forEach((roomObj) => {
      const roomName = Object.keys(roomObj)[0];
      const roomArray = Object.values(roomObj)[0];
      if (
        selectedOptionMealType === 'all-meal' ||
        roomArray.some((option) => option.meal === selectedOptionMealType)
      ) {
        roomNameSet.add(roomName);
      }
      if (
        selectedOptionRoomType === 'all-room' ||
        roomName === selectedOptionRoomType
      ) {
        roomArray.forEach((option) => {
          mealSet.add(option.meal);
        });
      }
    });

    setRoomGroupName(Array.from(roomNameSet));
    setRoomGroupMeal(Array.from(mealSet));
  }, [hotelRoomData, selectedOptionRoomType, selectedOptionMealType]);

  const rates: CurrencyRate[] = useSelector(
    (state: RootState) => state.currency.cryptoRates,
  );

  const fiatRates: CurrencyRate[] = useSelector(
    (state: RootState) => state.currency.fiatRates,
  );

  const selectedCrypto: SelectedCrypto = useSelector(
    (state: RootState) => state.currency.selectedCryptoWithNetwork,
  );

  const selectedFiat: string = useSelector(
    (state: RootState) => state.currency.selectedFiat || 'USD', // Provide a default
  );

  // Helper Functions
  const formatPrice = (price: number, conversionRate: number): number => {
    return Number(price) / Number(conversionRate);
  };

  // Find Crypto Rate
  const cryptoRateItem = rates.find(
    (item: CurrencyRate) =>
      item.ccy2 === selectedCrypto.cryptoName &&
      item.blockchain2 === selectedCrypto.blockchain2,
  );

  const cryptoRate = cryptoRateItem ? cryptoRateItem.rate : 0; // Default to 0 if not found

  // Find Fiat Rate
  const selectedFiatArray = fiatRates.find(
    (item: CurrencyRate) => item.ccy2 === selectedFiat,
  );

  // Ensure PriceRate is always a number
  const PriceRate = selectedFiat === 'EUR' ? 1 : selectedFiatArray?.rate ?? 1;

  // Determine decimal precision
  const precFiatVal = selectedFiatArray ? Number(selectedFiatArray.prec) : 2;

  // Initialize fiatPrice to 0 or a default value
  let fiatPrice = 0;

  // Only proceed if hotelRoomData is not empty
  if (hotelRoomData && hotelRoomData.length > 0) {
    const firstRoom = Object.values(hotelRoomData[0]) as any; // Get the first array of rooms
    // Get the first room object
    // Extract the first daily price, ensuring it's a number
    fiatPrice = firstRoom[0][0]['daily_prices'][0];
  }

  return (
    <>
      <div id='roomSection'>
        <div className='flex flex-col lg:flex-row lg:justify-between mt-10'>
          {/* Hotel Information */}
          <div>
            <p className='text-[32px] font-bold'>{data.name}</p>
            <div className='flex items-center'>
              <p>{data.address}</p>
            </div>
            <div className='flex items-center justify-between mt-4 gap-5'>
              <div className='flex items-center gap-2'>
                {data?.star_rating > 0 && (
                  <>
                    {Array.from({ length: data.star_rating }).map(
                      (_, index) => (
                        <img
                          key={`filled-${index}`}
                          className='w-[18px] h-[18px]'
                          src='/stay_search_img/rating-star-fill.svg'
                          alt={`Star ${index + 1}`}
                        />
                      ),
                    )}
                    {Array.from({ length: 5 - data.star_rating }).map(
                      (_, index) => (
                        <img
                          key={`empty-${index}`}
                          className='w-[18px] h-[18px]'
                          src='/stay_search_img/rating-star-blank.svg'
                          alt={`Empty Star ${index + 1}`}
                        />
                      ),
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Pricing Information */}
          <div className='flex flex-col mt-5 gap-6 lg:mt-0'>
            <div className='text-end'>
              {hotelRoomData && hotelRoomData.length > 0 ? (
                <>
                  <p className='text-[32px]'>
                    from{' '}
                    <span className='font-bold ml-1'>
                      {(fiatPrice * PriceRate).toFixed(precFiatVal)}{' '}
                      {selectedFiat}
                    </span>
                  </p>
                  <p className='text-[#002E8A]'>
                    Est.{' '}
                    <span className='font-bold'>
                      {cryptoRate
                        ? formatPrice(fiatPrice, cryptoRate).toFixed(
                            cryptoRateItem?.prec
                              ? Number(cryptoRateItem.prec)
                              : 2,
                          )
                        : 'N/A'}{' '}
                      {selectedCrypto.cryptoName}
                    </span>
                  </p>
                  <p>price per night</p>
                </>
              ) : (
                <p className='text-[18px] font-semibold'>
                  Not available at searched dates
                </p>
              )}
            </div>

            {/* Show "Choose room" button only if rooms are available */}
            {hotelRoomData && hotelRoomData.length > 0 && (
              <div className='w-full flex justify-end'>
                <a
                  href='#roomGroup'
                  className={`text-[#FFFFFF] bg-[#002E8A] font-bold w-full rounded-md py-3 px-3 flex justify-center pointer-events-auto opacity-100'
                 }`}
                >
                  Choose room
                </a>
              </div>
            )}
          </div>
        </div>

        {/* IMAGE CROUSEL AREA */}
        <Box sx={{ flexGrow: 1 }} className='mt-5 lg:mt-10'>
          <Grid container spacing={{ xs: 1, sm: 1.5 }}>
            {data?.images?.length > 0
              ? data.images
                  .slice(0, maxVisibleImages)
                  .map((image: string, index: number) => (
                    <Grid
                      key={index}
                      className={`relative ${
                        index < 2
                          ? 'w-full lg:w-1/2'
                          : index < 4
                          ? 'w-1/2 lg:w-1/4'
                          : 'w-full lg:w-1/6'
                      } ${index < 2 ? 'h-[250px] lg:h-[400px]' : 'h-[250px]'}`}
                    >
                      <img
                        className='w-full h-full object-cover cursor-pointer'
                        src={image.replace('{size}', '1024x768')}
                        alt=''
                        onClick={handleOpenModal} // Add this onClick handler
                      />
                      {index === maxVisibleImages - 1 &&
                        remainingImages > 0 && (
                          <div className='absolute top-0 p-1 bottom-0 right-0 w-full h-full overflow-hidden'>
                            <div className='bg-gradient-to-b from-transparent to-[#1E2E5E] w-full h-full flex items-center justify-center'>
                              <button
                                onClick={handleOpenModal}
                                className='py-1 rounded hover:text-[#f3e0e0] text-[#FFFFFF] border-b-2 font-bold text-[18px]'
                              >
                                +{remainingImages} photos
                              </button>
                            </div>
                          </div>
                        )}
                    </Grid>
                  ))
              : null}
          </Grid>
        </Box>
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby='modal-title'
          aria-describedby='modal-description'
        >
          <Box className='w-[95%] h-[45%] lg:w-[85%] lg:h-[90%] my-[40%] mx-auto p-2 lg:p-3 bg-[#FFFFFF] flex items-center lg:my-[2.5%]'>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              navigation={true}
              modules={[Autoplay, Navigation, Pagination]}
            >
              {data?.images?.map(
                (image: string, index: React.Key | null | undefined) => (
                  <SwiperSlide key={index}>
                    <img
                      className='w-full h-full mx-auto'
                      src={image.replace('{size}', '1024x768')}
                      alt=''
                    />
                  </SwiperSlide>
                ),
              )}
            </Swiper>
            <IconButton
              onClick={handleCloseModal}
              aria-label='close'
              sx={{ position: 'absolute', top: 10, right: 20 }}
            >
              <CloseIcon className='text-[#FFFFFF]' />
            </IconButton>
          </Box>
        </Modal>
        {/* IMAGE CROUSEL AREA END*/}
        <div className='flex items-center lg:justify-between gap-4 my-10'>
          <div className='lg:flex w-full gap-4'>
            <a
              className='border rounded w-full py-4 text-[#1F2A37] font-medium flex justify-center mb-4 lg:mb-0'
              href='#roomSection'
            >
              Room selection
            </a>
            <a
              className='border rounded w-full py-4 text-[#1F2A37] font-medium flex justify-center'
              href='#houseRules'
            >
              House rules
            </a>
          </div>
          <div className='lg:flex w-full gap-4'>
            <a
              className='border rounded w-full py-4 text-[#1F2A37] font-medium flex justify-center mb-4 lg:mb-0'
              href='#reviews'
            >
              Reviews
            </a>
            <a
              className='border rounded w-full py-4 text-[#1F2A37] font-medium flex justify-center'
              href='#facilities'
            >
              Facilities
            </a>
          </div>
        </div>

        <div className='grid lg:grid-cols-[100%] gap-12'>
          <div className='flex order-2 flex-col gap-4 lg:order-1'>
            {data?.description_struct?.map((section, sectionIndex) => (
              <div key={sectionIndex}>
                <h2 className='font-bold'>{section.title}</h2>
                {section?.paragraphs?.map((paragraph, paragraphIndex) => (
                  <p key={paragraphIndex}>{parse(paragraph)}</p>
                ))}
              </div>
            ))}
          </div>
        </div>

        {/* Room Details start */}
        <div id='roomGroup'>
          <div className='mt-4'>
            <p className='text-[24px] font-bold'>Available rooms</p>
            {hotelRoomData && hotelRoomData.length > 0 ? (
              <>
                <div className='flex flex-col lg:items-center lg:flex-row gap-4 mt-10'>
                  <p className='text-center lg:text-start'>Filter rooms by: </p>
                  <FormControl
                    fullWidth
                    size='small'
                    className='w-full lg:max-w-[380px]'
                  >
                    <InputLabel id='demo-simple-select-label1'>
                      Room type
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label1'
                      id='demo-simple-select1'
                      value={selectedOptionRoomType}
                      label='Room type'
                      onChange={handleChangeRoomType}
                    >
                      <MenuItem value={'all-room'}>All Room Types</MenuItem>
                      {roomGroupName.map((room: any, index: number) => (
                        <MenuItem key={index} value={room}>
                          {room}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    fullWidth
                    size='small'
                    className='w-full lg:max-w-[240px]'
                  >
                    <InputLabel id='demo-simple-select-label2'>
                      Meals
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label2'
                      id='demo-simple-select2'
                      value={selectedOptionMealType}
                      label='Meals'
                      onChange={handleChangeMealType}
                    >
                      <MenuItem value={'all-meal'}>All Meal</MenuItem>
                      {roomGroupMeal.map((meal: any, index: number) => (
                        <MenuItem key={index} value={meal}>
                          {meal
                            .split('-')
                            .map(
                              (word: any) =>
                                word.charAt(0).toUpperCase() + word.slice(1),
                            )
                            .join(' ')}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                {/* Render Room Groups */}
                {(selectedOptionRoomType === 'all-room'
                  ? hotelRoomData
                  : hotelRoomData.filter(
                      (room) => Object.keys(room)[0] === selectedOptionRoomType,
                    )
                ).map((room) => {
                  const [roomName, roomArray] = Object.entries(room)[0] as any;
                  const filteredRoomArray = roomArray.filter((option: any) => {
                    const isMealMatch =
                      selectedOptionMealType === 'all-meal' ||
                      option.meal === selectedOptionMealType;
                    return isMealMatch;
                  });

                  if (filteredRoomArray.length > 0) {
                    return (
                      <HotelRoomGroup
                        key={roomName}
                        room={{ roomName, roomArray: filteredRoomArray }}
                        roomCount={roomCount}
                      />
                    );
                  }
                  return null;
                })}
              </>
            ) : (
              <p className='text-center text-[18px] font-semibold mt-10'>
                Not available at searched dates
              </p>
            )}
          </div>
        </div>

        <HotelHouseRules data={data} />
        <HotelFacilities data={data} />
      </div>
    </>
  );
};

export default HotelDetails;
