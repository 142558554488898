import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails, addGuestDetails, clearGuestDetails } from '../../../Redux/slices/user-slice';
import { RootState } from '../../../Redux/store';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { TextField, MenuItem, Select, FormControl } from '@mui/material';

interface Guest {
  first_name: string;
  last_name: string;
  age?: number;
  is_child?: boolean;
}

const InputGuestDetails: React.FC = () => {
  const dispatch = useDispatch();

  const searchVar = useSelector((state: RootState) => state.searchVar);
  const roomDetails = searchVar.roomDetails;
  const { userDetails, inputErrors } = useSelector(
    (state: RootState) => state.user,
  );

  useEffect(() => {
    dispatch(clearGuestDetails());
    if (roomDetails) {
      roomDetails.forEach((room, roomIndex) => {
        // Add adult guests
        for (let i = 0; i < room.adults; i++) {
          dispatch(
            addGuestDetails({
              roomIndex,
              guest: {
                first_name: "",
                last_name: "",
              },
            })
          );
        }

        // Add child guests
        room.children.forEach((age, childIndex) => {
          dispatch(
            addGuestDetails({
              roomIndex,
              guest: {
                first_name: "",
                last_name: "",
                age,
                is_child: true,
              },
            })
          );
        });
      });
    }
  }, [roomDetails, dispatch]);

  useEffect(() => {
    if (userDetails.guestDetails.length > 0) {
      const updatedGuests = JSON.parse(JSON.stringify(userDetails.guestDetails));

      if (
        updatedGuests[0] &&
        updatedGuests[0].guests[0] &&
        (updatedGuests[0].guests[0].first_name !== userDetails.firstName ||
          updatedGuests[0].guests[0].last_name !== userDetails.lastName)
      ) {
        // Only update if there's a difference
        updatedGuests[0].guests[0].first_name = userDetails.firstName;
        updatedGuests[0].guests[0].last_name = userDetails.lastName;
        dispatch(setUserDetails({ guestDetails: updatedGuests }));
      }
    }
  }, [userDetails.firstName, userDetails.lastName, userDetails.guestDetails, dispatch]);

  // Main guest form handler
  const handleMainGuestChange = (event: { target: any }) => {
    const { name, value } = event.target;
    dispatch(setUserDetails({ [name]: value }));
  };

  // Other guest details form handler
  const handleOtherGuestsChange = (roomIndex: number, guestIndex: number, field: keyof Guest, value: string | number) => {
    const updatedGuests = [...userDetails.guestDetails];
    if (updatedGuests[roomIndex]) {
      updatedGuests[roomIndex] = {
        ...updatedGuests[roomIndex],
        guests: updatedGuests[roomIndex].guests.map((guest, index) =>
          index === guestIndex ? { ...guest, [field]: value } : guest
        ),
      };
    }
    dispatch(setUserDetails({ guestDetails: updatedGuests }));
  };

  return (
    <div className='shadow-md rounded-xl bg-[#FFFFFF] p-3 lg:p-5 mt-5 mb-5 flex flex-col gap-4'>
      <div className='border-b-2 pb-2'>
        <p className='text-[#000000] text-[24px]'>Main Guests details</p>
      </div>
      <div className='flex items-center w-full gap-4 flex-col lg:flex-row'>
        <div className='w-full'>
          <FormControl className='w-full flex flex-col gap-2'>
            <p>Title* {inputErrors.title && (<span className='text-red-500'>({inputErrors.title})</span>)}</p>
            <Select
              size='small'
              name='title'
              value={userDetails.title}
              onChange={handleMainGuestChange}
              autoComplete='off'
              displayEmpty
            >
              <MenuItem value='' disabled>
                Title
              </MenuItem>
              <MenuItem value='Mr'>Mr</MenuItem>
              <MenuItem value='Ms'>Ms</MenuItem>
              <MenuItem value='Mrs'>Mrs</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className='w-full flex flex-col gap-2'>
          <p className='text-[#111928] text-[14px] font-medium'>
            First name*{' '}
            {inputErrors.firstName && (
              <span className='text-red-500'>({inputErrors.firstName})</span>
            )}
          </p>
          <TextField
            fullWidth
            size='small'
            name='firstName'
            label='Your first name'
            value={userDetails.firstName}
            onChange={handleMainGuestChange}
            autoComplete='off'
          />
        </div>
      </div>
      <div className='flex items-center w-full gap-4 flex-col lg:flex-row'>
        <div className='w-full flex flex-col gap-2'>
          <p className='text-[#111928] text-[14px] font-medium'>
            Middle name{' '}
            {inputErrors.middleName && (
              <span className='text-red-500'>({inputErrors.middleName})</span>
            )}
          </p>
          <TextField
            fullWidth
            size='small'
            name='middleName'
            label='Your middle name (If Any)'
            value={userDetails.middleName}
            onChange={handleMainGuestChange}
            autoComplete='off'
          />
        </div>

        <div className='w-full flex flex-col gap-2'>
          <p className='text-[#111928] text-[14px] font-medium'>
            Last name*{' '}
            {inputErrors.lastName && (
              <span className='text-red-500'>({inputErrors.lastName})</span>
            )}
          </p>
          <TextField
            fullWidth
            size='small'
            name='lastName'
            label='Your last name'
            value={userDetails.lastName}
            onChange={handleMainGuestChange}
            autoComplete='off'
          />
        </div>
      </div>

      <div className='flex w-full gap-4 flex-col lg:flex-row'>
        <div className='w-full flex flex-col gap-2'>
          <p className='text-[#111928] text-[14px] font-medium'>
            Phone* {inputErrors.phone && (<span className='text-red-500'>({inputErrors.phone})</span>)}
          </p>
          <div style={{ width: '100%' }}>
            <PhoneInput
              international
              value={userDetails.phone}
              onChange={(value: any) =>
                handleMainGuestChange({ target: { name: 'phone', value } })
              }
              className='border border-[#c4c4c4] rounded-md p-2'
            />
          </div>
        </div>
        <div className='w-full flex flex-col gap-2'>
          <p className='text-[#111928] text-[14px] font-medium'>
            Email*{' '}
            {inputErrors.email && (
              <span className='text-red-500'>({inputErrors.email})</span>
            )}
          </p>
          <TextField
            fullWidth
            size='small'
            name='email'
            type='email'
            label='Your email'
            value={userDetails.email}
            onChange={handleMainGuestChange}
            autoComplete='off'
          />
          <p className='text-[#374151] text-[14px]'>
            Booking confirmation will be sent to this email address.
          </p>
        </div>
      </div>

      <div className='w-full flex flex-col gap-2'>
        <p className='text-[#111928] text-[14px] font-medium'>
          Additional requests{' '}
          {inputErrors.additionalRequests && (
            <span className='text-red-500'>
              ({inputErrors.additionalRequests})
            </span>
          )}
        </p>
        <TextField
          fullWidth
          size='small'
          name='additionalRequests'
          label='Add here your additional requests'
          multiline
          value={userDetails.additionalRequests}
          onChange={handleMainGuestChange}
          autoComplete='off'
        />

        {/* Dynamic Room Guest Details */}
        {roomDetails.map((room, roomIndex) => (
          <div key={roomIndex} className="w-full mt-4">
            <p className="text-[#000000] text-[24px] mb-3">Room {roomIndex + 1} Guests</p>

            {/* Adults Fields */}
            {Array.from({ length: room.adults }).map((_, guestIndex) => (
              <div key={`adult-${guestIndex}`} className="flex-col flex gap-3 mb-4 lg:flex-row lg:items-center">
                <div className='w-[100px]'>
                  <p className="text-[#000000] text-[17px]">Adult {guestIndex + 1} :</p>
                </div>
                <div className="flex-col w-full flex gap-3 lg:flex-row lg:items-center">
                  <TextField
                    fullWidth
                    size="small"
                    name="first name"
                    label={`First Name ${inputErrors.guestDetails?.[roomIndex]?.[guestIndex]?.first_name || ''}`}
                    value={roomIndex === 0 && guestIndex === 0 ? userDetails.firstName : userDetails.guestDetails[roomIndex]?.guests[guestIndex]?.first_name || ''}
                    onChange={(e) => handleOtherGuestsChange(roomIndex, guestIndex, 'first_name', e.target.value)}
                    required
                    disabled={roomIndex === 0 && guestIndex === 0}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label={`Last Name ${inputErrors.guestDetails?.[roomIndex]?.[guestIndex]?.last_name || ''}`}
                    value={roomIndex === 0 && guestIndex === 0 ? userDetails.lastName : userDetails.guestDetails[roomIndex]?.guests[guestIndex]?.last_name || ''}
                    onChange={(e) => handleOtherGuestsChange(roomIndex, guestIndex, 'last_name', e.target.value)}
                    required
                    disabled={roomIndex === 0 && guestIndex === 0}
                  />
                  {/* <TextField
                  className='w-full lg:w-[25%]'
                  size="small"
                  label="Age"
                  type="number"
                  value={userDetails.guestDetails[roomIndex]?.guests[guestIndex]?.age || 18}
                  onChange={(e) => {
                    const newAge = parseInt(e.target.value, 10);
                    if (newAge >= 18 && newAge <= 100) {
                      handleOtherGuestsChange(roomIndex, guestIndex, 'age', newAge);
                    }
                  }}
                  inputProps={{
                    min: 18,
                    max: 100,
                  }}
                /> */}
                </div>
              </div>
            ))}

            {/* Children Fields */}
            {room.children.map((childAge, childIndex) => (
              <div key={`child-${childIndex}`} className="flex-col flex gap-3 mb-4 lg:flex-row lg:items-center">
                <div className='w-[100px]'>
                  <p className="text-[#000000] text-[17px]">Child {childIndex + 1} :</p>
                </div>
                <div className="flex-col w-full flex gap-3 lg:flex-row lg:items-center">
                  <TextField
                    fullWidth
                    size="small"
                    label={`First Name ${inputErrors.guestDetails?.[roomIndex]?.[room.adults + childIndex]?.first_name || ''}`}
                    value={userDetails.guestDetails[roomIndex]?.guests[room.adults + childIndex]?.first_name || ''}
                    onChange={(e) =>
                      handleOtherGuestsChange(roomIndex, room.adults + childIndex, 'first_name', e.target.value)
                    }
                    required
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label={`Last Name ${inputErrors.guestDetails?.[roomIndex]?.[room.adults + childIndex]?.last_name || ''}`}
                    value={userDetails.guestDetails[roomIndex]?.guests[room.adults + childIndex]?.last_name || ''}
                    onChange={(e) =>
                      handleOtherGuestsChange(roomIndex, room.adults + childIndex, 'last_name', e.target.value)
                    }
                    required
                  />
                  <TextField
                    className='w-full lg:w-[150px]'
                    size="small"
                    label="Age"
                    type="number"
                    value={childAge}
                    disabled
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
export default InputGuestDetails;

