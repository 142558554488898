import React from 'react';
import { Skeleton } from '@mui/material';

const PriceSummarySkeleton: React.FC = () => {
  return (
    <div>
      <div className='mt-5'>
        <div className='text-center'>
          <p className='text-[18px] font-bold'>Finding Prices Please Wait...</p>
        </div>
        <Skeleton className='w-[200px] h-[20px] lg:w-[300px] lg:h-[40px]' />
        <div className='my-3 flex justify-between'>
          <div className='w-[40%]'>
            <Skeleton className='w-[120px] lg:w-[220px]' />
            <Skeleton className='w-[100px] lg:w-[200px]' />
            <Skeleton className='w-[80px] lg:w-[100px]' />
            <Skeleton className='w-[100px] lg:w-[200px]' />
          </div>
          <div className='w-[40%]'>
            <div className='flex justify-end'>
              <Skeleton className='w-[80px] lg:w-[180px]' />
            </div>
            <div className='flex justify-end'>
              <Skeleton className='w-[50px] lg:w-[150px]' />
            </div>
            <div className='flex justify-end'>
              <Skeleton className='w-[80px] lg:w-[180px]' />
            </div>
            <div className='flex justify-end'>
              <Skeleton className='w-[120px] lg:w-[220px]' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PriceSummarySkeleton;
