import React, { useEffect, useState } from 'react';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    data: any;
    onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, data, onPageChange }) => {
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsButtonEnabled(true);
        }, 0);

        return () => {
            clearTimeout(timer);
            setIsButtonEnabled(false);
        };
    }, [currentPage]);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const pageChange = (index: number): void => {
        onPageChange(index);
    }

    const handlePrevPage = () => {
        if (currentPage > 0) {
            onPageChange(currentPage - 1);
        }
    };
    const pagesArray = Array.from({ length: totalPages + 1 }, (_, i) => ({ index: i }));
    return (
        <div className="flex justify-between">
            <button
                onClick={handlePrevPage}
                disabled={currentPage === 0 || !isButtonEnabled}
                className="px-3 py-1 lg:px-4 lg:py-2 bg-gray-300 rounded disabled:opacity-50"
            >
                Prev
            </button>

            <div className="flex gap-2">
                {pagesArray.map((page) => (
                    <button
                        key={page.index}
                        className={`px-3 py-1 lg:px-4 lg:py-2 rounded disabled:opacity-50 ${page.index === currentPage ? 'bg-gray-400' : 'bg-gray-300'
                            }`}
                        onClick={() => pageChange(page.index)}
                        disabled={!isButtonEnabled || (page.index > currentPage && data.length < 9)}
                    >
                        <span className={page.index === currentPage ? 'font-bold' : 'font-normal'}>
                            {page.index + 1}
                        </span>
                    </button>
                ))}
            </div>

            <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages || !isButtonEnabled || data.length < 9}
                className="px-3 py-1 lg:px-4 lg:py-2 bg-gray-300 rounded disabled:opacity-50"
            >
                Next
            </button>
        </div>
    );
};
export default Pagination;

