import React from 'react'
interface PaymentBookingDetailsProps {
    order: any;
}

const PaymentBookingDetails: React.FC<PaymentBookingDetailsProps> = ({ order }) => {
    const totalGuest = Number(Number(order?.total_guests_adult || 0) + Number(order?.total_guests_children || 0));
    return (
        <>
            <div className='shadow-md rounded-xl p-3 lg:p-5 flex flex-col gap-4 bg-[#FFFFFF]'>
                <div className='flex justify-center mb-5'>
                    <div className='flex flex-col gap-5'>
                        <p className='text-[24px] font-medium'>Your booking details</p>
                        {/* <button
                            disabled={!prebookhashExists}
                            className='text-[14px] font-medium'
                        >
                            <span className='border-[#000000] border-b'>
                                {prebookhashExists ? 'Edit booking details' : 'Loading'}
                            </span>
                        </button> */}
                    </div>
                </div>

                <div className='flex justify-around'>
                    <div className='text-center flex flex-col gap-1'>
                        <p>Check-in</p>
                        <div>
                            <p className='text-[14px]'>{order?.checkin_time}</p>
                        </div>
                    </div>
                    <div className='text-center flex flex-col gap-1'>
                        <p>Check-out</p>
                        <div>
                            <p className='text-[14px]'>{order?.checkout_time}</p>
                        </div>
                    </div>
                </div>

                <table>
                    <tr className=''>
                        <td>
                            <p>Total length of stay</p>
                            <p className='lg:text-[18px] font-[600]'>{order?.length_of_stay}</p>
                        </td>
                        <td>
                            <p className='text-start'>Total guests</p>
                            <p className='lg:text-[18px] font-[600]'>{totalGuest}</p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <p>Room type</p>
                            <p className='lg:text-[18px] font-[600]'>{order?.room_type}</p>
                        </td>
                        <td>
                            <p>No. of rooms</p>
                            <p className='lg:text-[18px] font-[600]'>{order?.no_of_rooms}</p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <p>Meal type</p>
                            <p className='lg:text-[18px] font-[600]'>
                                {order?.board_type
                                    ? order?.board_type.replace(/-/g, ' ').toLowerCase().replace(/^\w/, (c: any) => c.toUpperCase())
                                    : ''}
                            </p>
                        </td>
                    </tr>
                </table>
            </div>
        </>
    );
};
export default PaymentBookingDetails;