import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stepper from '../Components/Shared/stepper';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import ConfirmationNumber from '../Components/Views/Payment/confirmation-number';
import HotelDetailsCard from '../Components/Views/Guest-details/hotel-details-card';
import PaymentPriceSummary from '../Components/Views/Payment/payment-price-summary';
import PaymentBookingDetails from '../Components/Views/Payment/payment-booking-details';
import BarcodePay from '../Components/Views/Payment/barcode-pay';
import Popup from '../Components/Views/Payment/payment-message-popup';
import Navbar from '../Components/Shared/navbar';

interface Hotel {
  address: string;
  amenity_groups: any[];
  check_in_time: string;
  check_out_time: string;
  name: string;
  id: string;
  star_rating: number;
  hotel_image: string;
  [key: string]: any;
}

const Payment = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [order, setOrder] = useState<any | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [prevOrderStatus, setPrevOrderStatus] = useState<string>('');
  const [hasShownPopup, setHasShownPopup] = useState(false);
  const [expireTime, setExpireTime] = useState<any>(null);
  const [timeLeft, setTimeLeft] = useState<string>('00:00');
 
  const orderId = params.get('orderId');
  const phoneNumber = params.get('phone_number');

  const mapDataToProps = (item: Hotel) => {
    const roomAmenities = item.amenity_groups
      ?.find(group => group.group_name === 'General')
      ?.amenities
    const dummyRoomAmenities = ['wifi', 's-no-smoke', 'corn', 'pc', 'gym'];
    return {
      image:
        item?.hotel_image ||
        'https://via.placeholder.com/150',
      star: item?.star_rating || 0,
      rating: item?.rating || 0,
      reviews: item?.reviews || 0,
      name: item?.hotel_name || 'No name available',
      address: item?.hotel_address || 'No address available',
      distance: item?.distance || '',
      amenities: roomAmenities || dummyRoomAmenities,
      id: item?.id || '',
    };
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!orderId || !phoneNumber) {
        setOrder({ ErrorMessage: 'Missing parameters' });
        return;
      }
      try {
        const response = await axios.get(
          `https://euuql6gjy3.execute-api.eu-north-1.amazonaws.com/dev/myOrder?orderId=${orderId}&phone_number=${phoneNumber}`,
        );
        if (response.status === 200) {
          const orderData = response.data;
          setOrder(orderData);
          setExpireTime(orderData?.expire_date);
          if (orderData.orderStatus !== prevOrderStatus) {
            setShowPopup(true);
            setPrevOrderStatus(orderData.orderStatus);
            setHasShownPopup(false);

            setTimeout(() => {
              setShowPopup(false);
              setHasShownPopup(true);
            }, 10000);
          } else if (!hasShownPopup) {
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 10000);
            setHasShownPopup(true);
          }
        } else {
          throw new Error('Unexpected response status');
        }
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          if (err.response.status === 404) {
            setOrder({
              ErrorMessage: err.response.data.Error || 'Order not found',
            });
          } else {
            setOrder({
              ErrorMessage: err.message || 'Failed to fetch order details',
            });
          }
        } else {
          setOrder({ ErrorMessage: 'Network or other error' });
        }
        console.log(err);
      }
    };
    const intervalId = setInterval(fetchOrderDetails, 60000);
    fetchOrderDetails();

    return () => clearInterval(intervalId);
  }, [orderId, phoneNumber, prevOrderStatus, hasShownPopup]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    const startCountdown = (expireTime: number) => {
      interval = setInterval(() => {
        const currentTime = Date.now();
        let timeDifference = expireTime - currentTime;
        
        if (timeDifference <= 999) {
          clearInterval(interval);
          setTimeLeft('00:00:00');
          setExpireTime(null);
          return;
        }

        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        setTimeLeft(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
      }, 1000);
    };

    if ((order?.orderStatus === 'waiting_payment' || order?.orderStatus === 'partial_payment') && expireTime) {
      startCountdown(expireTime);
    } 

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [expireTime, order?.orderStatus]);

  return (
    <div className='bg-[#F8F8F8]'>
      <Navbar/>
      <div className='container mx-auto px-[1rem] lg:px-[1.5rem] py-[2%] mx-w-[1536px]'>
        <Stepper />

        {showPopup && (
          <Popup order={order} timeLeft={timeLeft} onClose={() => setShowPopup(false)} />
        )}
        {order ? (
          order.ErrorMessage ? (
            <div className='bg-white shadow-md rounded-lg p-3 my-8'>
              <p>{order.ErrorMessage}</p>
            </div>
          ) : (
            (order.orderStatus === 'payment_expired' || timeLeft === "00:00:00") ? (
              <div className='bg-white shadow-md rounded-lg p-3 my-8'>
                <p>The payment expired, please search again.</p>
              </div>
            ) : (
              <>
                <ConfirmationNumber orderDetails={order} timeLeft={timeLeft} />
                <HotelDetailsCard data={mapDataToProps(order)} />
                <div className='grid lg:grid-cols-[50%_50%] gap-5 my-5'>
                  <PaymentBookingDetails order={order} />
                  <div className='shadow-md rounded-xl p-3 lg:p-5 bg-[#FFFFFF]'>
                    <PaymentPriceSummary order={order} />
                    <BarcodePay orderDetails={order} timeLeft={timeLeft} />
                  </div>
                </div>
              </>
            ))
        ) : (
          <div className='flex w-full h-[80vh] justify-center items-center'>
            <div className='bg-slate-100 rounded-md p-3 justify-center flex items-center' style={{ boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px' }}>
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Payment;
