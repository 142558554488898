import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchHotelsByRegion } from '../Redux/slices/search-slice';
import { AppDispatch } from '../Redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchStay from '../Components/Shared/search-stay';
import Stepper from '../Components/Shared/stepper';
import SearchResult from '../Components/Views/Search/search-result';
import Pagination from '../Components/Views/Search/pagination';
import { format } from 'date-fns';
import SearchResultSkeleton from '../Components/Skeleton/search-result-skeleton';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface Hotel {
  address: string;
  amenity_groups: any[];
  id: string;
  images: string[];
  name: string;
  room_groups: any[];
  star_rating: number;
  daily_min: number;
  [key: string]: any;
}
interface Option {
  id: string;
  name: string;
  designation: string;
  group: string;
}

const Search: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  // const results = useSelector((state: RootState) => state.search.data);

  // Get initial values from URL parameters
  const initialPage = parseInt(params.get('page') || '0');
  const initialRegionId = params.get('region_id') || '';
  const inputValue = params.get('inputValue') || '';
  const group = params.get('group') || '';
  const designation = params.get('designation') || '';
  const startDate = params.get('startDate') || '';
  const endDate = params.get('endDate') || '';
  const roomDetails = JSON.parse(params.get('roomDetails') || '[]');
  const initialSort = params.get('sort') || 'popularity';

  const pureRoomDetails = params.get('roomDetails') || '[]';
  let totalRooms = roomDetails.length;
  let totalAdults = 0;
  let totalChildren = 0;

  roomDetails.forEach((room: { adults: number; children: number[] }) => {
    totalAdults += room.adults;
    totalChildren += room.children.length;
  });
  let totalGuests = totalAdults + totalChildren;

  const [selectedOption, setSelectedOption] = useState<Option>({
    id: initialRegionId,
    name: inputValue,
    group: group,
    designation: designation,
  });

  useEffect(() => {
    setSelectedOption({
      id: initialRegionId,
      name: inputValue,
      group: group,
      designation: designation,
    });
  }, [initialRegionId, inputValue, group, designation]);

  const [showSearchStay, setShowSearchStay] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(initialPage || 0);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [paginationError, setPaginationError] = useState<any>(null);
  const [selectedSortOption, setSelectedSortOption] =
    useState<string>(initialSort);

  const dateFormat = 'dd MMM yyyy';
  const totalPages = 4;

  // Update data fetching to include sort parameter
  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchHotelsByRegion({
        page: currentPage,
        region_id: initialRegionId,
        checkin_date: startDate,
        checkout_date: endDate,
        guest_array: pureRoomDetails,
        sort:
          selectedSortOption !== 'popularity' ? selectedSortOption : undefined,
      }),
    )
      .unwrap()
      .then((response) => {
        setData(response.hotels);
      })
      .catch((error) => {
        setPaginationError(error);
        console.error('Error fetching hotels:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    currentPage,
    initialRegionId,
    startDate,
    endDate,
    totalRooms,
    totalAdults,
    totalChildren,
    pureRoomDetails,
    selectedSortOption, // Include selectedSortOption in dependency array
  ]);

  const handleEditClick = () => {
    setShowSearchStay(!showSearchStay);
  };

  const mapDataToProps = (item: Hotel) => {
    const roomAmenities = item.amenity_groups?.find(
      (group) => group.group_name === 'General',
    )?.amenities;
    const roomDummyAmenities = ['wifi', 's-no-smoke', 'corn', 'pc', 'gym'];
    return {
      image:
        item.images[0]?.replace('{size}', '1024x768') ||
        'https://via.placeholder.com/150',
      star: item.star_rating || 0,
      rating: item.rating || 0,
      reviews: item.reviews || 0,
      name: item.name || 'No name available',
      address: item.address || 'No address available',
      distance: item.distance || '',
      amenities: roomAmenities || roomDummyAmenities,
      id: item.id || '',
      daily_min: item.daily_min || 0,
    };
  };

  const checkinDateObj = startDate ? new Date(startDate) : undefined;
  const checkoutDateObj = endDate ? new Date(endDate) : undefined;

  const handlePageChange = async (newPage: number) => {
    setCurrentPage(newPage);

    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', newPage.toString());

    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  // Handle sorting changes
  const handleChangeSorting = (event: any) => {
    const sortOption = event.target.value;
    setSelectedSortOption(sortOption);

    const queryParams = new URLSearchParams(location.search);

    if (sortOption === 'popularity') {
      queryParams.delete('sort');
    } else {
      queryParams.set('sort', sortOption);
    }

    // Reset page to 0 when sorting changes
    queryParams.set('page', '0');
    setCurrentPage(0);

    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  return (
    <>
      <div className='h-10 bg-[#002E8A] flex justify-between container px-[1rem] mx-auto items-center text-white lg:hidden'>
        <div className=''>
          <p className='text-[14px]'>
            {inputValue
              ? inputValue.length > 8
                ? `${inputValue.substring(0, 10)}..`
                : inputValue
              : 'Hotel'}
          </p>
        </div>
        <p className='text-[12px]'>
          {format(new Date(startDate), dateFormat)} -{' '}
          {format(new Date(endDate), dateFormat)}
        </p>
        <p className='text-[12px]'>
          {totalGuests} {totalGuests > 1 ? 'Guests' : 'Guest'}
        </p>
        <button onClick={handleEditClick}>
          <img src='/stay_search_img/edit-3.png' alt='' />
        </button>
      </div>
      {showSearchStay && (
        <div className='flex'>
          <SearchStay
            initialInputValue={inputValue}
            initialSelectedOption={selectedOption}
            initialStartDate={checkinDateObj}
            initialEndDate={checkoutDateObj}
            initialRooms={roomDetails}
            initialPage={initialPage}
            initialRegionId={initialRegionId}
          />
        </div>
      )}

      <div className='bg-[#F8F8F8]'>
        <div className='stay-middle-div container mx-auto px-[1rem] lg:px-[1.5rem] py-[2%] mx-w-[1536px]'>
          <div className='hidden lg:block'>
            <SearchStay
              initialInputValue={inputValue}
              initialSelectedOption={selectedOption}
              initialStartDate={checkinDateObj}
              initialEndDate={checkoutDateObj}
              initialRooms={roomDetails}
              initialPage={initialPage}
              initialRegionId={initialRegionId}
            />
          </div>
          <div className='mt-10'>
            <Stepper />
          </div>

          <div className='flex flex-row items-start mt-10 gap-10'>
            <div className='w-full flex flex-col gap-5 justify-end lg:w-[100%]'>
              <>
                <div className=''>
                  <div className='flex items-center justify-between'>
                    <p className='hidden lg:block'>
                      {data.length} of{' '}
                      <span className='font-bold'>
                        {data.length} properties
                      </span>
                    </p>
                    <div className='shadow hidden lg:block'>
                      <FormControl
                        sx={{ minWidth: 220 }}
                        fullWidth
                        size='small'
                      >
                        <InputLabel id='demo-simple-select-label'>
                          Sort Results
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          value={selectedSortOption}
                          label='Sort Results'
                          onChange={handleChangeSorting}
                        >
                          <MenuItem value='popularity'>Most Popular</MenuItem>
                          <MenuItem value='asc'>Ascending Price</MenuItem>
                          <MenuItem value='desc'>Descending Price</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                {!loading ? (
                  <>
                    {data.length > 0 ? (
                      data.map((item: any, index: number) => {
                        return (
                          <SearchResult
                            key={index}
                            data={mapDataToProps(item)}
                          />
                        );
                      })
                    ) : (
                      <div>
                        <p>No search results found.</p>
                        <p>{paginationError}</p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {Array.from({ length: 9 }).map((_, index) => (
                      <SearchResultSkeleton key={index} />
                    ))}
                  </>
                )}
                {data.length > 0 ? (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    data={data}
                    onPageChange={handlePageChange}
                  />
                ) : null}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
