import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import {
  Box,
  Modal,
  TextField,
  IconButton,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setSearchParams } from '../../Redux/slices/search-var-slice';
import { AppDispatch } from '../../Redux/store';
import { addDays } from 'date-fns';
import Cookies from 'js-cookie';
import axiosInstance from '../../utils/axiosConfig';

interface Room {
  rooms: number;
  adults: number;
  children: number[];
}

interface Option {
  id: string;
  name: string;
  designation: string;
  group: string;
}

interface searchStayProps {
  initialInputValue: string;
  initialSelectedOption: Option;
  initialStartDate?: Date;
  initialEndDate?: Date;
  initialRooms?: Room[];
  initialPage?: number;
  initialRegionId?: string;
  initialHotelId?: string;
}

const SearchStay: React.FC<searchStayProps> = ({
  initialInputValue,
  initialSelectedOption,
  initialStartDate,
  initialEndDate,
  initialRooms,
  initialPage,
  initialRegionId,
}) => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>(initialInputValue);
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option>(
    initialSelectedOption,
  );
  const [openDateModal, setOpenDateModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const today = new Date();
  const sevenDaysLater = addDays(today, 7);
  const [startDate, setStartDate] = useState<Date | undefined>(
    initialStartDate || today,
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    initialEndDate || sevenDaysLater,
  );
  const [rooms, setRooms] = useState<Room[]>(
    initialRooms || [{ rooms: 1, adults: 2, children: [] }],
  );
  const page = initialPage || 0;
  const [regionId, setRegionId] = useState<string>(initialRegionId || '');

  const [checkinDate, setCheckinDate] = useState<string>('');
  const [checkoutDate, setCheckoutDate] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const dateFormat = 'dd MMM yyyy';

  const handleOpenDate = () => setOpenDateModal(true);
  const handleCloseDate = () => setOpenDateModal(false);
  const handleOpenUser = () => setOpenUserModal(true);
  const handleCloseUser = () => setOpenUserModal(false);

  const handleCloseClearDate = () => {
    setStartDate(today);
    setEndDate(sevenDaysLater);
    setOpenDateModal(false);
  };

  const handleCloseClearUser = () => {
    setRooms([{ rooms: 1, adults: 2, children: [] }]);
    setOpenUserModal(false);
  };

  const handleSetChildCount = (index: number, count: number) => {
    const updatedRooms = rooms.map((room, i) => {
      if (i === index) {
        const children = room.children.slice(0, count);
        while (children.length < count) {
          children.push(1);
        }
        return { ...room, children };
      }
      return room;
    });
    setRooms(updatedRooms);
  };

  const handleSetChildAge = (
    index: number,
    childIndex: number,
    age: number,
  ) => {
    const updatedRooms = rooms.map((room, i) => {
      if (i === index) {
        const children = room.children.map((child, j) => {
          if (j === childIndex) {
            return age;
          }
          return child;
        });
        return { ...room, children };
      }
      return room;
    });
    setRooms(updatedRooms);
  };

  const handleSetAdultCount = (index: number, count: number) => {
    const updatedRooms = rooms.map((room, i) =>
      i === index ? { ...room, adults: count } : room,
    );
    setRooms(updatedRooms);
  };

  const addRoom = () => {
    if (rooms.length < 10) {
      setRooms([...rooms, { rooms: 1, adults: 2, children: [] }]);
    }
  };

  const removeRoom = (index: number) => {
    setRooms(rooms.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const s_id = Cookies.get('s_id') || '';
    if (inputValue && inputValue.length > 3) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await axiosInstance.post(
            `/serp/autocomplete?s_id=${s_id}`,
            {
              query: inputValue,
              language: 'en',
            },
          );
          const json = response.data;
          if (json.data) {
            setOptions(
              [...json.data.hotels, ...json.data.regions].map((item: any) => ({
                id: item.id,
                name: item.name,
                designation: item.type || 'Hotel',
                group: item.type ? 'Regions' : 'Hotels',
              })),
            );
          }
          if (json.s_id) {
            Cookies.set('s_id', json.s_id, { expires: 1 });
          }
        } catch (error) {
          console.error('Failed to fetch autocomplete data:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    } else {
      setOptions([]);
    }
  }, [inputValue]);

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string,
  ) => {
    setInputValue(newInputValue);
  };

  const handleOptionChange = (
    event: React.ChangeEvent<{}>,
    value: string | Option | null,
  ) => {
    if (typeof value === 'string') {
      setRegionId(value);
      setSelectedOption({
        id: value,
        name: value,
        designation: '',
        group: '',
      });
    } else if (value !== null) {
      setRegionId(value.id);
      setSelectedOption(value);
    } else {
      setRegionId('');
      setSelectedOption({
        id: '',
        name: '',
        designation: '',
        group: '',
      });
    }
  };
  const setDateRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    if (start && end) {
      const diffInDays = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);

      if (diffInDays > 30) {
        const adjustedEndDate = new Date(start.getTime() + 30 * 24 * 60 * 60 * 1000);
        setStartDate(start);
        setEndDate(adjustedEndDate);
      } else {
        setStartDate(start);
        setEndDate(end);
      }
    } else {
      setStartDate(start ?? undefined);
      setEndDate(end ?? undefined);
    }
    if (end) {
      setOpenDateModal(false);
    }
  };

  const dayClassName = (date: Date) => {
    if (startDate && !endDate) {
      const diffInDays = (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
      if (diffInDays > 30) {
        return "disabled-day";
      }
    }
    return "";
  };
  // const dayTooltip = (date: Date) => {
  //   if (startDate) {
  //     const diffInDays = (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
  //     if (diffInDays > 30) {
  //       return "Select max 30 days";
  //     }
  //   }
  //   return "";
  // };

  useEffect(() => {
    if (startDate) {
      setCheckinDate(format(startDate, 'yyyy-MM-dd'));
    } else {
      setCheckinDate('N/A');
    }

    if (endDate) {
      setCheckoutDate(format(endDate, 'yyyy-MM-dd'));
    } else {
      setCheckoutDate('N/A');
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (checkinDate && checkoutDate) {
      const validCheckinDate = !isNaN(new Date(checkinDate).getTime())
        ? checkinDate
        : null;
      const validCheckoutDate = !isNaN(new Date(checkoutDate).getTime())
        ? checkoutDate
        : null;
      dispatch(
        setSearchParams({
          startDate: validCheckinDate,
          endDate: validCheckoutDate,
          roomDetails: rooms,
          hotelId: selectedOption ? selectedOption.id : null,
        }),
      );
    }
  }, [dispatch, checkinDate, checkoutDate, rooms, selectedOption]);

  const handleSearch = async () => {
    if (checkinDate === 'N/A' || checkoutDate === 'N/A' || !selectedOption) {
      console.error('Missing required parameters');
      return;
    }

    if (selectedOption.group === 'Hotels') {
      setLoading(true);
      try {
        handleHotelClick(
          selectedOption.id,
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else if (selectedOption.group === 'Regions') {
      setLoading(true);
      try {
        const queryParams = new URLSearchParams({
          page: page.toString(),
          region_id: regionId,
          group: selectedOption.group,
          designation: selectedOption.designation,
          inputValue: inputValue,
          startDate: checkinDate,
          endDate: checkoutDate,
          roomDetails: JSON.stringify(rooms),
          showAll: 'true',
        }).toString();

        navigate(`/hotelsByRegion?${queryParams}`, {
          state: {
            selectedOption,
            inputValue: inputValue,
            startDate: checkinDate,
            endDate: checkoutDate,
            roomDetails: rooms,
          },
        });
      } catch (error) {
        console.error('Failed to fetch search data:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleHotelClick = (hotelId: any) => {
    const params = new URLSearchParams({
      id: hotelId,
      checkin: checkinDate,
      checkout: checkoutDate,
      guests: JSON.stringify(rooms),
    });
    navigate(`/hotels?${params.toString()}`);
  };

  return (
    <>
      <div id='dateSelecter' className='top-all-div w-full'>
        <div className='stay-top-buttons-div flex w-auto md:w-full sm:w-full'>
          <div className='stay-top-button-grp gap-2 bg-[#F3F4F6] overflow-hidden w-full md:w-full sm:w-full lg:w-auto'>
            <a
              href='https://test.instacoins.travel'
              className='stay-top-button w-full justify-center hover:bg-[#FFFFFF] cursor-pointer hover:border-b-2 hover:border-blue-900 transition duration-300 ease-in-out'
            >
              <img
                className='w-[20px] h-[20px] lg:w-[24px] lg:h-[24px]'
                src='/stay_home_img/flight_icon.png'
                alt=''
              />
              <p>Flights</p>
            </a>
            <div className='stay-top-button w-full selecteds bg-white justify-center hover:bg-[#FFFFFF] cursor-pointer hover:border-b-2 hover:border-blue-900 transition duration-300 ease-in-out'>
              <img
                className='w-[20px] h-[20px] lg:w-[24px] lg:h-[24px]'
                src='/stay_home_img/stay_icon.png'
                alt=''
              />
              <p>Stays</p>
            </div>
          </div>
        </div>
        <div className='flex gap-4 w-full justify-between items-center shadow pt-7 pb-3 px-3 flex-col bg-[#FFFFFF] rounded-bl-lg rounded-br-lg rounded-tr-none lg:flex-row lg:rounded-tr-lg lg:px-7 lg:py-10'>
          <div className='flex w-full border-gray-100 flex-col gap-4 lg:flex-row lg:border lg:border-[#D1D5DB] lg:rounded-lg lg:items-center'>
            <div className='flex items-center px-3 w-full border border-gray-300 gap-2 rounded-md lg:border-none'>
              <img
                className='w-[15px] h-[16px] lg:w-[24px] lg:h-[24px]'
                src='/stay_home_img/map-pin.png'
                alt=''
              />
              <Autocomplete
                freeSolo
                options={options}
                groupBy={(option) =>
                  typeof option === 'string' ? '' : option.group
                }
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.name
                }
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onChange={handleOptionChange}
                value={selectedOption}
                isOptionEqualToValue={(option, value) => {
                  if (typeof option === 'string' || typeof value === 'string') {
                    return option === value;
                  }
                  return option.id === value?.id;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Search for a region or hotel'
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        padding: '0',
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                        },
                      },
                      '& .MuiInputBase-root': {
                        paddingRight: '0px !important',
                      },
                      '& .MuiInputBase-input': {
                        padding: '0px 0px',
                        color: selectedOption.name ? 'black' : 'red',
                      },
                      '& .MuiInputLabel-root': {
                        display: 'none',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '@media (max-width:1000px)': {
                        '& .MuiOutlinedInput-root': {
                          padding: '6px 0px',
                        },
                      },
                    }}
                  />
                )}
                sx={{ width: '100%' }}
              />
            </div>
            <div className='flex items-center w-full gap-4 flex-col lg:flex-row'>
              <div className='flex items-center gap-3 px-3 w-full border border-gray-300 rounded-lg lg:border-none'>
                <img
                  className='w-[16px] h-[16px] lg:w-[24px] lg:h-[24px]'
                  src='/stay_home_img/calendar.png'
                  alt=''
                />
                <button
                  className='min-w-[230px] w-full flex py-3'
                  onClick={handleOpenDate}
                >
                  {startDate && endDate ? (
                    <p>
                      {format(startDate, dateFormat)} -{' '}
                      {format(endDate, dateFormat)}
                    </p>
                  ) : (
                    <p className='text-gray-400'>Select Date</p>
                  )}
                </button>
                <Modal
                  open={openDateModal}
                  onClose={handleCloseDate}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      bgcolor: 'background.paper',
                      boxShadow: 24,
                      p: 4,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div className='flex justify-between items-center w-full py-2'>
                      <div className='w-full'>
                        {startDate && endDate ? (
                          <p>
                            {format(startDate, dateFormat)} -{' '}
                            {format(endDate, dateFormat)}
                          </p>
                        ) : (
                          'Select Date (Max 30 Nights)'
                        )}
                      </div>
                      <div className='border border-gray-300 rounded-lg w-[30px] h-[30px] flex justify-center'>
                        <IconButton
                          onClick={handleCloseClearDate}
                          aria-label='close'
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update: [Date | null, Date | null]) => {
                        setDateRange(update);
                      }}
                      minDate={new Date()}
                      inline
                      monthsShown={2}
                      dayClassName={dayClassName}
                    />
                  </Box>
                </Modal>
              </div>
              <div className='flex items-center gap-4 w-full px-3 border border-gray-300 rounded-lg lg:border-none'>
                <button
                  className='min-w-[230px] w-full flex py-3'
                  onClick={handleOpenUser}
                >
                  {rooms.length && rooms[0].adults ? (
                    <p className='m-0 p-0 text-black'>
                      {rooms.reduce((total, room) => total + room.rooms, 0)}{' '}
                      Room,{' '}
                      {rooms.reduce((total, room) => total + room.adults, 0)}{' '}
                      Adults,{' '}
                      {rooms.reduce(
                        (total, room) => total + room.children.length,
                        0,
                      )}{' '}
                      Children
                    </p>
                  ) : (
                    <p className='text-gray-400'>Select User</p>
                  )}
                </button>
                <Modal
                  open={openUserModal}
                  onClose={handleCloseUser}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                  className='overflow-y-auto'
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      bgcolor: 'background.paper',
                      boxShadow: 24,
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      maxHeight: '85%',
                      overflowY: 'auto',
                      borderRadius: '8px'
                    }}
                  >
                    <div className='flex flex-col gap-5 min-w-[280px] lg:min-w-[400px] pt-3 relative'>
                      <div className='flex absolute right-0 mr-0 top-0'>
                        <IconButton
                          onClick={handleCloseClearUser}
                          aria-label='close'
                        >
                          <CloseIcon className='border border-[#D1D5DB] rounded-md' />
                        </IconButton>
                      </div>
                      {rooms.map((room, index) => (
                        <div key={index} className='px-2'>
                          <div className='flex items-center justify-between'>
                            <p className='font-medium text-[16px]'>{`Room ${index + 1
                              }`}</p>
                            {index > 0 && (
                              <div className=''>
                                <div className='w-full mb-2'>
                                  <div className='left-0 absolute w-full h-[1px] bg-[#E5E7EB]'></div>
                                </div>
                                <div>
                                  <IconButton
                                    aria-label='delete'
                                    onClick={() => removeRoom(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className='flex flex-row justify-between items-center mt-3'>
                            <div className='flex flex-col lg:gap-2 lg:flex-row'>
                              <p>Adults</p>
                              <p className='text-[#6B7280]'>(age 18+)</p>
                            </div>
                            <div className='flex items-center gap-3'>
                              <button
                                className={`px-3 py-1 border rounded-full ${rooms[index].adults <= 1 ? 'shadow-inner bg-slate-100' : 'shadow-lg bg-[#FFFFFF]'
                                  }`}
                                onClick={() =>
                                  handleSetAdultCount(
                                    index,
                                    Math.max(rooms[index].adults - 1, 1),
                                  )
                                }
                                disabled={rooms[index].adults <= 1}
                              >
                                -
                              </button>
                              <p>{room.adults}</p>
                              <button
                                className={`px-3 py-1 border rounded-full ${rooms[index].adults >= 6 ? 'shadow-inner bg-slate-100' : 'shadow-lg bg-[#FFFFFF]'
                                  }`}
                                onClick={() =>
                                  handleSetAdultCount(
                                    index,
                                    Math.min(rooms[index].adults + 1, 6),
                                  )
                                }
                                disabled={rooms[index].adults >= 6}
                              >
                                +
                              </button>
                            </div>
                          </div>

                          <div className='flex flex-row justify-between items-center mt-3'>
                            <div className='flex flex-col lg:gap-2 lg:flex-row'>
                              <p>Children</p>
                              <p className='text-[#6B7280]'>(age 1 - 17)</p>
                            </div>
                            <div className='flex items-center gap-3'>
                              <button
                                className={`px-3 py-1 border rounded-full ${rooms[index].children.length <= 0 ? 'shadow-inner bg-slate-100' : 'shadow-lg bg-[#FFFFFF]'
                                  }`}
                                onClick={() =>
                                  handleSetChildCount(
                                    index,
                                    Math.max(
                                      rooms[index].children.length - 1,
                                      0,
                                    ),
                                  )
                                }
                                disabled={rooms[index].children.length <= 0}
                              >
                                -
                              </button>

                              <p>{room.children.length}</p>
                              <button
                                className={`px-3 py-1 border rounded-full ${rooms[index].children.length >= 4 ? 'shadow-inner bg-slate-100' : 'shadow-lg bg-[#FFFFFF]'
                                  }`}
                                onClick={() =>
                                  handleSetChildCount(
                                    index,
                                    Math.min(
                                      rooms[index].children.length + 1, 4),
                                  )
                                }
                                disabled={rooms[index].children.length >= 4}
                              >
                                +
                              </button>
                            </div>
                          </div>

                          {room.children.map((age, childIndex) => (
                            <div className='mt-3' key={childIndex}>
                              <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth size='small'>
                                  <InputLabel
                                    id={`demo-simple-select-label-${childIndex}`}
                                  >
                                    Select age
                                  </InputLabel>
                                  <Select
                                    labelId={`demo-simple-select-label-${childIndex}`}
                                    id={`demo-simple-select-${childIndex}`}
                                    value={age}
                                    label='Select age'
                                    onChange={(e) =>
                                      handleSetChildAge(
                                        index,
                                        childIndex,
                                        parseInt(e.target.value as string, 10),
                                      )
                                    }
                                  >
                                    {Array.from({ length: 17 }, (_, i) => (
                                      <MenuItem key={i} value={i + 1}>
                                        {i + 1}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          ))}
                        </div>
                      ))}
                      <div className='flex gap-3'>
                        <button
                          className={`w-full flex border border-[#D1D5DB] px-3 py-2 rounded-md justify-center  ${rooms.length >= 9 ? 'shadow-inner bg-slate-100' : 'shadow-md bg-[#FFFFFF]'
                            }`}
                          onClick={addRoom}
                          disabled={rooms.length >= 9}
                        >
                          +1 Room
                        </button>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
          <div className='w-full flex lg:w-2/12 min-w-max'>
            {!inputValue || !selectedOption.id || !selectedOption.name || !startDate || !endDate || !rooms ? (
              <Tooltip title='Select any region or hotel' arrow>
                <span className='w-full'>
                  <button
                    className='items-center text-[#FFFFFF] py-3 px-2 w-full rounded-lg bg-[#002E8A] opacity-50 cursor-not-allowed'
                    disabled
                  >
                    {loading ? (
                      <CircularProgress size={18} color='inherit' />
                    ) : (
                      <div className='items-center flex justify-center gap-3'>
                        <p>Search stays</p>
                        <img src='/stay_home_img/search_icon.png' alt='' />
                      </div>
                    )}
                  </button>
                </span>
              </Tooltip>
            ) : (
              <button
                onClick={handleSearch}
                className='items-center text-[#FFFFFF] py-3 px-2 w-full rounded-lg bg-[#002E8A]'
              >
                {loading ? (
                  <CircularProgress size={18} color='inherit' />
                ) : (
                  <div className='items-center flex justify-center gap-3'>
                    <p>Search stays</p>
                    <img src='/stay_home_img/search_icon.png' alt='' />
                  </div>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default SearchStay;
