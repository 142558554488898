import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/store';

interface BookingDetailsProps {
  hotelInfo: any;
  totalGuest: number;
}

const BookingDetails: React.FC<BookingDetailsProps> = ({hotelInfo, totalGuest}) => {
  // const formatDateTime = (dateString: string | null): string => {
  //   if (!dateString) {
  //     return 'Date not available';
  //   }

  //   const date = new Date(dateString);
  //   if (isNaN(date.getTime())) {
  //     return 'Invalid date';
  //   }

  //   const optionsDate: Intl.DateTimeFormatOptions = {
  //     day: '2-digit',
  //     month: 'short',
  //     year: 'numeric',
  //   };
  //   const optionsTime: Intl.DateTimeFormatOptions = {
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     hour12: false,
  //   };
  //   const formattedDate = date.toLocaleDateString('en-GB', optionsDate);
  //   const formattedTime = date.toLocaleTimeString('en-GB', optionsTime);

  //   return `${formattedTime} on ${formattedDate}`;
  // };

  const formatDate = (dateString: string | null): string => {
    if (!dateString) {
      return 'Date not available';
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }

    return new Intl.DateTimeFormat('en-GB', {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).format(date);
  };

  const results = useSelector((state: RootState) => state.room.roomInfo);
  const roomType = results?.room?.room_data_trans?.main_room_type;
  // const refundable =
  //   results?.room?.payment_options?.payment_types?.[0]?.cancellation_penalties
  //     ?.free_cancellation_before || '';
  const mealType = results?.room?.meal || '';
  const roomCount = results?.roomCount;

  const searchInfo = useSelector((state: RootState) => state.searchVar);
  const startDate = searchInfo?.startDate; // e.g., "2024-11-05T00:00:00.000Z"
  const endDate = searchInfo?.endDate; // e.g., "2024-11-12T00:00:00.000Z"

  // Implementing totalNight calculation
  let totalNight = 0;
  if (startDate && endDate) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (!isNaN(startDateObj.getTime()) && !isNaN(endDateObj.getTime())) {
      const diffInMs = endDateObj.getTime() - startDateObj.getTime();
      totalNight = diffInMs / (1000 * 60 * 60 * 24);
    } else {
      console.error('Invalid startDate or endDate');
      // Handle invalid dates if necessary
    }
  } else {
    console.error('startDate or endDate is missing');
    // Handle missing dates if necessary
  }

  return (
    <div className='shadow-md rounded-xl bg-[#FFFFFF] p-3 lg:p-5 flex flex-col gap-6'>
      <p className='text-[#000000] font-bold'>Your booking details</p>
      <div className='flex items-start justify-between'>
        <div>
          <p>Check-in</p>
          <p className='lg:text-[18px] font-bold'>{formatDate(startDate)}</p>
          <p className='text-[14px]'>{hotelInfo.check_in_time}</p>
        </div>
        <div>
          <p>Check-out</p>
          <p className='lg:text-[18px] font-bold'>{formatDate(endDate)}</p>
          <p className='text-[14px]'>{hotelInfo.check_out_time}</p>
        </div>
      </div>

      <div>
        <p>Total length of stay</p>
        <p className='lg:text-[18px] font-bold'>
          {totalNight} night{totalNight !== 1 ? 's' : ''}
        </p>
      </div>

      <div className='flex items-start justify-between'>
        <div>
          <p>Room type</p>
          <p className='lg:text-[18px] font-bold'>
            {roomType || 'Room type not available'}
          </p>
        </div>
        <div className='text-center'>
          <p>No. of rooms</p>
          <p className='lg:text-[18px] font-bold'>{roomCount || 0}</p>
        </div>
      </div>

      <div>
        <p>Meal type</p>
        <p className='lg:text-[18px] font-bold'>
          {mealType
            ? mealType
                .replace(/-/g, ' ')
                .toLowerCase()
                .replace(/^\w/, (c: string) => c.toUpperCase())
            : 'Meal type not available'}
        </p>
      </div>

      {/* <div>
        <p>Refundability</p>
        {refundable ? (
          <>
            <p className='lg:text-[18px] font-bold'>Refundable</p>
            <p className='text-[14px]'>before {formatDateTime(refundable)}</p>
          </>
        ) : (
          <p className='lg:text-[18px] font-bold'>Not refundable</p>
        )}
      </div> */}
    </div>
  );
};

export default BookingDetails;
