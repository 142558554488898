import React, { useState, useEffect } from 'react';
import SearchStay from '../Components/Shared/search-stay';
import StayInfo from '../Components/Views/Home/stay-info';
import NextDestination from '../Components/Views/Home/next-destination';
import StayAnywhere from '../Components/Views/Home/stay-anywhere';
import { Navigate } from 'react-router-dom';

interface Option {
  id: string;
  name: string;
  designation: string;
  group: string;
}

const Home: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<Option>({
    id: '',
    name: '',
    group: '',
    designation: '',
  });
  useEffect(() => {
    setSelectedOption({
      id: '', name: '', group: '', designation: ''
    });
  }, []);

  const authToken = localStorage.getItem('authToken');

  if (!authToken) {
    return <Navigate to='/login' replace />;
  }
  return (
    <>
      <div className='main-page bg-[#F8F8F8]'>
        <div className='back-img'>
          <div className='px-[1rem] lg:px-[1.5rem] stay-top-div container py-[8%] mx-auto mx-w-[1536px]'>
            <div className='main-heading-div pb-[8%]'>
              <h1 className='main-heading'>Stay anywhere worldwide!</h1>
            </div>
            <div className='mt-[1%]'>
              <SearchStay
                initialInputValue={''}
                initialSelectedOption={selectedOption}
              />
            </div>
          </div>
        </div>
        <div className='px-[1rem] lg:px-[1.5rem] container mx-auto mt-[2%] mx-w-[1536px]'>
          <StayInfo />
          <NextDestination />
          <StayAnywhere />
        </div>
      </div>
    </>
  );
};
export default Home;
