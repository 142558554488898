import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography } from '@mui/material';
import axiosInstance, { setAuthToken } from '../utils/axiosConfig';

const Login = () => {
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post('/auth', { password });
      const { token } = response.data;

      if (token) {
        setAuthToken(token);
        navigate('/');
      } else {
        setErrorMessage('Authentication failed. No token received.');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setErrorMessage('Authentication failed. Please check your credentials.');
    }
  };

  return (
    <Container
      component='main'
      maxWidth='xs'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10vh',
      }}
    >
      <Typography component='h1' variant='h5'>
        Sign in
      </Typography>
      <form onSubmit={handleLogin} style={{ width: '100%', marginTop: 1 }}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          name='password'
          label='Password'
          type='password'
          id='password'
          autoComplete='current-password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          type='submit'
          fullWidth
          variant='outlined'
          color='primary'
          style={{ marginTop: '10px' }}
        >
          Login
        </Button>
        {errorMessage && (
          <Typography color='error' style={{ marginTop: '20px' }}>
            {errorMessage}
          </Typography>
        )}
      </form>
    </Container>
  );
};

export default Login;
