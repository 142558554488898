import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RoomDetails {
  rooms: number;
  adults: number;
  children: any[];
}

interface SearchState {
  startDate: string | null;
  endDate: string | null;
  roomDetails: RoomDetails[];
  hotelId: string | null;
}

const initialState: SearchState = {
  startDate: '',
  endDate: '',
  roomDetails: [{ rooms: 1, adults: 2, children: [] }],
  hotelId: null,
};

export const searchVarSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchParams: (state, action: PayloadAction<Partial<SearchState>>) => {
      state.startDate = action.payload.startDate
        ? new Date(action.payload.startDate).toISOString()
        : null;
      state.endDate = action.payload.endDate
        ? new Date(action.payload.endDate).toISOString()
        : null;
      state.roomDetails = action.payload.roomDetails ?? state.roomDetails;
      state.hotelId = action.payload.hotelId ?? state.hotelId;
    },
  },
});

export const { setSearchParams } = searchVarSlice.actions;
export default searchVarSlice.reducer;
