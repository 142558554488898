import React from 'react'
import parse from 'html-react-parser';

interface PolicyStruct {
  paragraphs: string[];
  title: string;
}

interface HotelInfoData {
  name: string;
  check_in_time: string;
  check_out_time: string;
  metapolicy_extra_info: string;
  policy_struct: PolicyStruct[];
}

interface HotelHouseRulesProps {
  data: HotelInfoData;
}

const HotelHouseRules: React.FC<HotelHouseRulesProps> = ({ data }) => {
  const checkInTime = data?.check_in_time || "00:00:00";
  const [checkInHour] = checkInTime.split(':').map(Number);
  const checkInProgressWidth = `${(checkInHour / 24) * 100}%`;

  const checkOutTime = data?.check_out_time || "00:00:00";
  const [checkOutHour] = checkOutTime.split(':').map(Number);
  const checkOutProgressWidth = `${(checkOutHour / 24) * 100}%`;

  return (
    <div id="houseRules">
      <div className='mt-8 mx-auto flex justify-center'>
        <div className='w-full lg:w-[60%] flex flex-col gap-8'>
          <div className='w-full lg:w-[60%] flex flex-col gap-8'>
            <p className='text-[20px] font-bold'>House rules</p>
            <p className='text-[#001A4D]'>{data?.name} takes special requests - add in the next step!</p>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2 w-[150px] lg:w-[25%]'>
                <img src="/stay_hotel_img/calendar.svg" alt="" />
                <p>Check-in</p>
              </div>
              <div className='w-[75%]'>
                <div className='flex items-center justify-end gap-24'>
                  <p>{data?.check_in_time}</p>
                  <p className='text-end'>00:00</p>
                </div>
                <div className='w-[100%] h-[25px] border border-[#002E8A] bg-[#002E8A] rounded-full flex overflow-hidden justify-start'>
                  <div
                    className='h-[25px] bg-[#FFFFFF]'
                    style={{ width: checkInProgressWidth }}
                  ></div>
                </div>
                <p className='text-center'>{data?.check_in_time} - 00:00</p>
                <div>
                </div>
              </div>
            </div>

            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2 w-[150px] lg:w-[25%]'>
                <img src="/stay_hotel_img/calendar.svg" alt="" />
                <p>Check-out</p>
              </div>
              <div className='w-[75%]'>
                <div className='flex items-center justify-end gap-24'>
                  <p>{data?.check_out_time}</p>
                  <p>00:00</p>
                </div>
                <div className='w-[100%] h-[25px] border border-[#002E8A] bg-[#002E8A] rounded-full flex overflow-hidden justify-start'>
                  <div
                    className='h-[25px] bg-[#FFFFFF]'
                    style={{ width: checkOutProgressWidth }}
                  ></div>
                </div>
                <p className='text-center'>{data?.check_out_time} - 00:00</p>
              </div>
            </div>
          </div>

          <div className='mt-8'>
            <table>
              <tbody className='info-tbody'>
                {data?.policy_struct?.map((policy, policyIndex) => (
                  <React.Fragment key={policyIndex}>
                    <tr>
                      <td className='font-bold pt-4 pl-0 pr-5 align-top'>{policy.title}</td>
                      <td className='pl-2'>
                        {policy?.paragraphs?.map((paragraph, paragraphIndex) => (
                          <div key={paragraphIndex} className='pt-1 p-0 leading-normal'>
                            {parse(paragraph)}
                          </div>
                        ))}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HotelHouseRules;