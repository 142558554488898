import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RoomInfo {
  roomCount: any;
  room: any;
}

interface PaymentType {
  amount: number;
  cancellation_penalties: {
    free_cancellation_before: string | null;
  };
  tax_data: {
    taxes: Taxes[];
  };
}
interface Taxes {
  name: string;
  included_by_supplier: boolean;
  amount: string;
  currency_code: string;
}

interface RoomState {
  roomInfo: RoomInfo | undefined;
  error: string | undefined;
}

const initialState: RoomState = {
  roomInfo: undefined,
  error: undefined,
};

const loadRoomInfoFromSession = (sessionId: string): RoomInfo | undefined => {
  const storedRoomInfo = sessionStorage.getItem(`roomInfo_${sessionId}`);
  return storedRoomInfo ? JSON.parse(storedRoomInfo) : undefined;
};

const getSessionIdFromCookies = (): string | null => {
  const match = document.cookie.match(new RegExp('(^| )s_id=([^;]+)'));
  return match ? match[2] : null;
};

const getInitialRoomState = (): RoomState => {
  const sessionId = getSessionIdFromCookies();
  const storedRoomInfo = sessionId
    ? loadRoomInfoFromSession(sessionId)
    : undefined;
  return {
    roomInfo: storedRoomInfo || undefined,
    error: undefined,
  };
};

export const roomSlice = createSlice({
  name: 'room',
  initialState: getInitialRoomState(),
  reducers: {
    setRoomInfo: (
      state,
      action: PayloadAction<{ sessionId: string; roomInfo: RoomInfo }>,
    ) => {
      const { sessionId, roomInfo } = action.payload;
      state.roomInfo = roomInfo;
      sessionStorage.setItem(`roomInfo_${sessionId}`, JSON.stringify(roomInfo));
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
  },
});

export const { setRoomInfo, setError } = roomSlice.actions;
export default roomSlice.reducer;
