import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/store';
import PriceSummarySkeleton from '../../Skeleton/price-summary-skeleton';
import axiosInstance from '../../../utils/axiosConfig';
import Cookies from 'js-cookie';

interface PriceSummaryProps {
  prebookStatus: string;
  setPrebookStatus: (status: string) => void;
}

const PriceSummary: React.FC<PriceSummaryProps> = ({
  prebookStatus,
  setPrebookStatus,
}) => {
  const rates = useSelector((state: RootState) => state.currency.cryptoRates);
  const fiatRates = useSelector((state: RootState) => state.currency.fiatRates);
  const [prebookData, setPrebookData] = useState<any>({});
  const selectedCrypto = useSelector(
    (state: RootState) => state.currency.selectedCryptoWithNetwork,
  );
  const cryptoRateItem = rates.find(
    (item: any) =>
      item.ccy2 === selectedCrypto.cryptoName &&
      item.blockchain2 === selectedCrypto.blockchain2,
  );
  const cryptoRate = cryptoRateItem ? cryptoRateItem.rate : 'Rate not found';
  const selectedFiat = useSelector(
    (state: RootState) => state.currency.selectedFiat,
  );
  let selectedFiatArray = fiatRates.find(
    (item: any) => item.ccy2 === selectedFiat,
  );
  let PriceRate = selectedFiat === 'EUR' ? 1 : selectedFiatArray?.rate;
  let precFiatVal = selectedFiatArray ? Number(selectedFiatArray.prec) : 2;
  const formatPrice = (price: any, conversionRate: any) => {
    return Number(price) / Number(conversionRate);
  };

  const results = useSelector((state: RootState) => state.room.roomInfo);
  // console.log('RESULTS', results?.room.book_hash);
  const roomType = results?.room?.room_data_trans?.main_room_type;
  const roomPriceAmount = prebookData?.prebook_price || 0;
  const roomCryptoPrice = results
    ? Number(
        formatPrice(roomPriceAmount, cryptoRate).toFixed(
          Number(cryptoRateItem?.prec),
        ),
      )
    : Number(0);
  const bookHash = results ? results.room.book_hash : null;

  const s_id = Cookies.get('s_id') || '';
  useEffect(() => {
    if (bookHash) {
      const fetchPrebookInfo = async () => {
        try {
          const body = { book_hash: bookHash };
          const response = await axiosInstance.post(
            '/hotelDetails/prebook',
            body,
            {
              params: {
                s_id: s_id,
              },
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );
          const dataFetch = response.data;
          setPrebookData(dataFetch.data);
          setPrebookStatus('success');
        } catch (error) {
          console.log('Error', error);
          setPrebookStatus('error');
        }
      };
      fetchPrebookInfo();
    } else {
      setPrebookStatus('error');
    }
  }, [bookHash, setPrebookStatus, s_id]);

  return (
    <>
      {prebookStatus === 'success' ? (
        <>
          <div className='shadow-md rounded-xl bg-[#FFFFFF] p-3 lg:p-5 mt-5 flex flex-col gap-2'>
            <p className='font-bold'>Your price summary</p>
            <div className='flex items-start justify-between gap-2'>
              <p className='w-[65%]'>{roomType}</p>
              <div className='flex justify-end w-[35%]'>
                <p>
                  {PriceRate &&
                    (prebookData.prebook_price * PriceRate).toFixed(precFiatVal,)}
                  <span> {selectedFiat}</span>
                </p>
              </div>
            </div>
            <div className='flex items-center justify-between'>
              <p>Total</p>
              <p>
                {PriceRate &&
                  (prebookData.prebook_price * PriceRate).toFixed(precFiatVal)}
                <span> {selectedFiat}</span>
              </p>
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2'>
                <p>Est. price in</p>
                {/* <img src="/stay_guest_details_img/coin.svg" alt="" /> */}
              </div>
              <p>
                {roomCryptoPrice || 0} {selectedCrypto.cryptoName}
              </p>
            </div>
          </div>
        </>
      ) : (
        <PriceSummarySkeleton />
      )}
    </>
  );
};
export default PriceSummary;
