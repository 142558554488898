import React, { useState, useEffect } from 'react';
import Stepper from '../Components/Shared/stepper';
import InputGuestDetails from '../Components/Views/Guest-details/input-guest-details';
import BookingDetails from '../Components/Views/Guest-details/booking-details';
import PriceSummary from '../Components/Views/Guest-details/price-summary';
import HotelDetailsCard from '../Components/Views/Guest-details/hotel-details-card';
import HotelDetailsCardSkeleton from '../Components/Skeleton/hotel-details-card-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import useSessionRedirect from '../Hooks/use-session-redirect';
import { validateForm } from '../Redux/slices/user-slice';
import Cookies from 'js-cookie';
import axiosInstance from '../utils/axiosConfig';

interface Hotel {
  address: string;
  amenity_groups: any[];
  check_in_time: string;
  check_out_time: string;
  description_struct: any[];
  email: string;
  facts: any;
  front_desk_time_end: string;
  front_desk_time_start: string;
  hotel_chain: string;
  id: string;
  images: string[];
  is_closed: boolean;
  is_gender_specification_required: boolean;
  kind: string;
  latitude: number;
  longitude: number;
  metapolicy_extra_info: string;
  metapolicy_struct: any;
  name: string;
  payment_methods: any[];
  phone: string;
  policy_struct: any[];
  postal_code: string;
  region: any;
  room_groups: any[];
  serp_filters: any[];
  star_certificate: string | null;
  star_rating: number;
  [key: string]: any;
}

const GuestDetails: React.FC = () => {
  useSessionRedirect();

  const navigate = useNavigate();
  const { hotelId } = useParams();
  const dispatch = useDispatch();

  const s_id = Cookies.get('s_id') || '';
  const { userDetails, loading, error, inputErrors } = useSelector(
    (state: any) => state.user,
  );

  const { selectedCryptoWithNetwork, selectedFiat } = useSelector(
    (state: any) => state.currency,
  );
  const { data: hotelInfo } = useSelector((state: any) => state.hotelInfo);
  const searchVar = useSelector((state: any) => state.searchVar);
  const { roomInfo } = useSelector((state: any) => state.room);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [loadingInfo, setLoadingInfo] = useState(false);
  const [localHotelInfo, setLocalHotelInfo] = useState<any>({});
  const [gettingResponseError, setGettingResponseError] = useState<any>(null);
  const [prebookStatus, setPrebookStatus] = useState('loading');

  useEffect(() => {
    const fetchHotelInfo = async () => {
      setLoadingInfo(true);
      try {
        const response = await axiosInstance.get('/hotelDetails/hotelInfo', {
          params: {
            hotel_id: hotelId,
            s_id: s_id,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const dataFetch = response.data;
        setLocalHotelInfo(dataFetch.data);
      } catch (error) {
        console.log('Error fetching availability:', error);
      } finally {
        setLoadingInfo(false);
      }
    };
    fetchHotelInfo();
  }, [hotelId, s_id]);

  const mapDataToProps = (item: Hotel) => {
    const roomAmenities = item.amenity_groups?.find(
      (group) => group.group_name === 'General',
    )?.amenities;
    const roomDummyAmenities = ['wifi', 's-no-smoke', 'corn', 'pc', 'gym'];
    return {
      image:
        item?.images?.[0]?.replace('{size}', '1024x768') ||
        'https://via.placeholder.com/150',
      star: item?.star_rating || 0,
      rating: item?.rating || 0,
      reviews: item?.reviews || 0,
      name: item?.name || 'No name available',
      address: item?.address || 'No address available',
      distance: item?.distance || '',
      amenities: roomAmenities || roomDummyAmenities,
      id: item?.id || '',
    };
  };

  const totalGuest = 0;

  useEffect(() => {
    dispatch(validateForm());
  }, [dispatch, userDetails]);

  const handlePaymentProceed = async () => {
    setButtonLoading(true);
    if (Object.keys(inputErrors).length === 0 || inputErrors === null) {
      const body = {
        userSearchInfo: {
          orderSearchDetails: {
              startDate: searchVar.startDate,
              endDate: searchVar.endDate,
              roomDetails: searchVar.roomDetails,
          },
          userDetails,
        },
        hotelInfo: {
          prebookDetails: {
            prebook_hash: '',
          },
          hotelDetails: {
            id: hotelInfo.id,
            name: hotelInfo.name,
            address: hotelInfo.address,
            check_in_time: hotelInfo.check_in_time,
            check_out_time: hotelInfo.check_out_time,
            phone: hotelInfo.phone,
            email: hotelInfo.email,
            description_struct: hotelInfo.description_struct,
            hotel_image: hotelInfo?.images[0]?.replace('{size}', '240x240') || 'https://via.placeholder.com/150',
            roomDetails: roomInfo,
          },
          roomDetails: roomInfo,
        },
        financialInfo: {
          fiatDetails: {
            selectedFiat,
          },
          cryptoDetails: {
            selectedCrypto: selectedCryptoWithNetwork.cryptoName,
            selectedCryptoNetwork: selectedCryptoWithNetwork.blockchain2,
          },
        },
      };
      console.log('Body', body);

      try {
        const response = await axiosInstance.post('/booking', body, {
          params: {
            s_id: s_id,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = response.data;

        if (data.orderId && data.phone_number) {
          navigate(
            `/payment?orderId=${data.orderId}&phone_number=${data.phone_number}`,
          );
        } else {
          throw new Error(data.message || 'Failed to generate address');
        }
      } catch (error: any) {
        setGettingResponseError(error?.response?.data?.error);
        console.log('Request failed:', error);
      } finally {
        setButtonLoading(false);
      }
    } else {
      setButtonLoading(false);
      console.log('Form contains errors');
    }
  };
  const authToken = localStorage.getItem('authToken');
  if (!authToken) {
    return <Navigate to='/login' replace />;
  }
  return (
    <div className='bg-[#F8F8F8]'>
      <div className='container mx-auto px-[1rem] lg:px-[1.5rem] py-[2%] mx-w-[1536px]'>
        <Stepper />

        <div className='grid lg:grid-cols-[65%_35%] gap-5 mt-10'>
          <div className=''>
            {loadingInfo ? (
              <HotelDetailsCardSkeleton />
            ) : (
              <HotelDetailsCard data={mapDataToProps(localHotelInfo)} />
            )}
            <InputGuestDetails />
          </div>

          <div>
            <BookingDetails hotelInfo={hotelInfo} totalGuest={totalGuest} />
            <PriceSummary
              prebookStatus={prebookStatus}
              setPrebookStatus={setPrebookStatus}
            />

            <div className='w-full my-5'>
              <button
                onClick={handlePaymentProceed}
                className={`rounded-md px-3 py-3 bg-[#002E8A] w-full text-[#FFFFFF] font-medium`}
              >
                {buttonLoading || loading || prebookStatus !== 'success' ? (
                  <CircularProgress size={18} color='inherit' />
                ) : (
                  `Review details and pay with ${selectedCryptoWithNetwork?.cryptoName}`
                )}
              </button>
              {error && <p>Error: {error}</p>}
              {gettingResponseError && <p>Error: {gettingResponseError}</p>}
              {/* {(Object.keys(inputErrors).length != 0 || inputErrors != null) ? (
                <p>Form contains errors</p>
              ):null} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GuestDetails;
