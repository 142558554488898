// import React, { useEffect, useState } from 'react';
// import { useParams, useLocation, Navigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { setHotelInfo } from '../Redux/slices/hotel-info-slice';
// import Cookies from 'js-cookie';

// import Stepper from '../Components/Shared/stepper';
// import HotelDetails from '../Components/Views/Hotel/hotel-room-section';
// import HotelHouseRules from '../Components/Views/Hotel/hotel-house-rules';
// import HotelFacilities from '../Components/Views/Hotel/hotel-facilities';
// import SearchStay from '../Components/Shared/search-stay';
// import HotelInfoSkeleton from '../Components/Skeleton/hotel-info-skeleton';
// import axiosInstance from '../utils/axiosConfig';

// interface Option {
//   id: string;
//   name: string;
//   designation: string;
//   group: string;
// }

// const Hotel: React.FC = () => {
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const { hotelId } = useParams<{ hotelId: string }>();

//   const params = new URLSearchParams(location.search);
//   const inputValue = params.get('inputValue') || '';
//   const group = params.get('group') || '';
//   const designation = params.get('designation') || '';
//   const startDate = params.get('startDate') || '';
//   const endDate = params.get('endDate') || '';
//   const roomDetails = JSON.parse(params.get('roomDetails') || '[]');

//   let totalAdults = 0;
//   let totalChildren = 0;
//   let childrenArray: number[] = [];

//   roomDetails.forEach((room: { adults: number; children: number[] }) => {
//     totalAdults += room.adults;
//     totalChildren += room.children.length;
//     childrenArray = room.children;
//   });

//   const checkinDateObj = startDate ? new Date(startDate) : undefined;
//   const checkoutDateObj = endDate ? new Date(endDate) : undefined;

//   const [selectedOption, setSelectedOption] = useState<Option>({
//     id: hotelId || '',
//     name: inputValue,
//     group: group,
//     designation: designation,
//   });
//   useEffect(() => {
//     setSelectedOption({
//       id: hotelId || '', name: inputValue, group: group, designation: designation
//     });
//   }, [hotelId, inputValue, group, designation]);

//   const [hotelInfo, setLocalHotelInfo] = useState<any>({});
//   const [availability, setAvailability] = useState<any | null>(null);
//   const [loadingInfo, setLoadingInfo] = useState(false);
//   const [loadingAvailability, setLoadingAvailability] = useState(false);
//   const s_id = Cookies.get('s_id') || '';

//   useEffect(() => {
//     const fetchHotelInfo = async () => {
//       setLoadingInfo(true);
//       try {
//         const response = await axiosInstance.get('/hotelDetails/hotelInfo', {
//           params: {
//             hotel_id: hotelId,
//             s_id: s_id,
//           },
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
//         const dataFetch = response.data;
//         setLocalHotelInfo(dataFetch.data);
//         dispatch(setHotelInfo(dataFetch.data));
//       } catch (error) {
//         console.error('Error fetching availability:', error);
//       } finally {
//         setLoadingInfo(false);
//       }
//     };
//     fetchHotelInfo();
//   }, [dispatch, hotelId, startDate, endDate, totalAdults, totalChildren, s_id]);

//   useEffect(() => {
//     const fetchAvailability = async () => {
//       setLoadingAvailability(true);
//       const body = {
//         checkin: startDate.toString(),
//         checkout: endDate.toString(),
//         guests: [
//           {
//             adults: totalAdults,
//             children: childrenArray,
//           },
//         ],
//         id: hotelId,
//       };
//       try {
//         const response = await axiosInstance.post(
//           '/hotelDetails/roomPrices',
//           body,
//           {
//             params: {
//               s_id: s_id,
//             },
//             headers: {
//               'Content-Type': 'application/json',
//             },
//           },
//         );
//         const data = response.data;
//         setAvailability(data.data);
//       } catch (error) {
//         console.error('Error fetching availability:', error);
//       } finally {
//         setLoadingAvailability(false);
//       }
//     };
//     fetchAvailability();
//   }, [hotelId, startDate, endDate, totalAdults, totalChildren, s_id]);

//   const authToken = localStorage.getItem('authToken');
//   if (!authToken) {
//     return <Navigate to='/login' replace />;
//   }

//   return (
//     <>
//       <div className='stay-middle-div bg-[#F8F8F8]'>
//         <div className='container mx-auto px-[1rem] lg:px-[1.5rem] py-[2%] mx-w-[1536px]'>
//           <Stepper />

//           {!loadingInfo && !loadingAvailability ? (
//             <>
//               {!loadingInfo &&
//                 !loadingAvailability &&
//                 availability?.hotels?.length > 0 &&
//                 availability?.hotels?.[0]?.rates?.length > 0 ? (
//                 <>
//                   <HotelDetails data={hotelInfo} hotelPageData={availability} />
//                   <HotelHouseRules data={hotelInfo} />
//                   <HotelFacilities data={hotelInfo} />
//                 </>
//               ) : (
//                 <div className='pt-[2%]'>
//                   <SearchStay
//                     initialInputValue={inputValue}
//                     initialSelectedOption={selectedOption}
//                     initialStartDate={checkinDateObj}
//                     initialEndDate={checkoutDateObj}
//                     initialRooms={roomDetails}
//                   />
//                   <div className='text-center mt-5'>
//                     <p className='text-[#001A4D]'>
//                       Room availability does not exist. Please select another
//                       date.
//                     </p>
//                   </div>

//                   <HotelDetails data={hotelInfo} hotelPageData={availability} />
//                   <HotelHouseRules data={hotelInfo} />
//                   <HotelFacilities data={hotelInfo} />
//                 </div>
//               )}
//             </>
//           ) : (
//             <HotelInfoSkeleton />
//           )}
//         </div>
//       </div>
//     </>
//   );
// };
// export default Hotel;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import axiosInstance from '../utils/axiosConfig';
import Stepper from '../Components/Shared/stepper';
import HotelInfoSkeleton from '../Components/Skeleton/hotel-info-skeleton';
import HotelDetails from '../Components/Views/Hotel/hotel-room-section';
import {
  setHotelInfo,
  setFetchStatus,
  setError,
  clearHotelInfo,
} from '../Redux/slices/hotel-info-slice';
import { RootState } from '../Redux/store';

const s_id = Cookies.get('s_id') || '';

const Hotel = () => {
  const dispatch = useDispatch();

  // Access hotelInfo data and other state from Redux store
  const hotelInfoData = useSelector((state: RootState) => state.hotelInfo.data);
  const fetchStatus = useSelector((state: RootState) => state.hotelInfo.status);
  const error = useSelector((state: RootState) => state.hotelInfo.error);

  // Local state for loading and other data not stored in Redux
  const [loading, setLoading] = useState(true);
  const [groupedRates, setGroupedRates] = useState([]);
  const [roomCount, setRoomCount] = useState(0);

  useEffect(() => {
    const fetchSingleHotelDetails = async () => {
      dispatch(clearHotelInfo()); // Clear existing hotel data
      dispatch(setFetchStatus('loading'));

      try {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');
        const checkin = params.get('checkin');
        const checkout = params.get('checkout');
        const guestsParam = params.get('guests');

        const response = await axiosInstance.get(
          '/hotelDetails/singleRoomDetails',
          {
            params: {
              s_id: s_id,
              id: id,
              checkin: checkin,
              checkout: checkout,
              guests: guestsParam,
            },
          },
        );
        console.log("Response", response.data);

        if (response && response.data.status === 'success') {
          const data = response.data.data;
          dispatch(setHotelInfo(data.hotelInfo)); // Update Redux state with hotelInfo
          setGroupedRates(data.groupedRates); // Set groupedRates in local state
          setRoomCount(data.roomCount); // Set roomCount in local state
          dispatch(setFetchStatus('succeeded'));
          setLoading(false);
        } else {
          dispatch(setFetchStatus('failed'));
          setLoading(false);
        }
      } catch (error) {
        dispatch(setError('Error fetching hotel details'));
        dispatch(setFetchStatus('failed'));
        setLoading(false);
        console.error('Error fetching hotel details:', error);
      }
    };

    fetchSingleHotelDetails();
  }, [dispatch]);

  if (fetchStatus === 'failed' && error) {
    return (
      <div className='stay-middle-div bg-[#F8F8F8]'>
        <div className='container mx-auto px-[1rem] lg:px-[1.5rem] py-[2%] mx-w-[1536px]'>
          <Stepper />
          <div className='text-center mt-5'>
            <p className='text-[#001A4D]'>{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='stay-middle-div bg-[#F8F8F8]'>
      <div className='container mx-auto px-[1rem] lg:px-[1.5rem] py-[2%] mx-w-[1536px]'>
        <Stepper />
        {loading || fetchStatus === 'loading' ? (
          <HotelInfoSkeleton />
        ) : (
          <HotelDetails
            data={hotelInfoData}
            hotelRoomData={groupedRates}
            roomCount={roomCount}
          />
        )}
      </div>
    </div>
  );
};

export default Hotel;
